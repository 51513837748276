<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { apiRequest } from "@/helpers/api-call";
import NumberInputSpinner from "vue-number-input-spinner";
import { format } from "date-fns";

export default {
  page: {
    title: "Nouveau",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    NumberInputSpinner,
  },
  data() {
    return {
      title: "Réservation",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Réservation",
          active: true,
        },
      ],
      tableData: [],
      value: null,
      users: [],
      trajet: {
        user: 0,
        type: "",
        nature: "",
        lieuDepart: {
          nom: "",
          latitude: 0,
          longitude: 0,
        },
        pointDeRencontre: {
          nom: "",
          latitude: 0,
          longitude: 0,
        },
        show: true,
        totalRows: 1,
        currentPage: 1,
        perPage: 100,
        pageOptions: [100, 250, 500],
        filter: null,
        filterOn: [],
        sortBy: "dateHeureDepart",
        sortDesc: false,
        fields: [
          { key: "itinéraire", sortable: true },
          { key: "conducteur", sortable: true },
          { key: "Depart", sortable: true },
          { key: "nombrePlace", sortable: true, label: "Places" },
          { key: "prix", sortable: true },
          { key: "passagers", sortable: true },
        ],
      },
    };
  },
  async mounted() {
    //Chargement des users
    const data = await apiRequest("GET", "admin/users", undefined, false);
    if (data && data.data) {
      //console.log("data.data:", data.data);
      const formattedTable = data.data.map((user) => {
        return {
          id: user.id,
          name: user.lastname[0] + ". " + user.firstname,
        };
      });
      this.users = formattedTable;
      //console.log('this.users:', this.users)
    }

    const dataTrajet = await apiRequest(
      "GET",
      "admin/trajets/avenir",
      undefined,
      false
    );
    if (dataTrajet && dataTrajet.data) {
      console.log("data.data:", dataTrajet.data);
      const formattedTable = dataTrajet.data.map((trajet) => {
        return {
          id: trajet.id,
          itinéraire:
            trajet.lieuDepart.nom.split(",")[0] +
            " >> " +
            trajet.lieuArrivee.nom.split(",")[0],
          conducteur:
            trajet.conducteur.personne.lastname[0] +
            ". " +
            trajet.conducteur.personne.firstname,
          Depart: format(
            new Date(trajet.dateHeureDepart),
            "dd-MM-yyyy hh:mm:ss"
          ),
          nombrePlace: trajet.nombrePlace,
          prix: trajet.prix + " XOF",
          passagers: 0 /*trajet.passagers.map((passager) => {
            if (passager) {
              return {
                nom:
                  passager.personne.lastname[0] +
                  ". " +
                  trajet.conducteur.personne.firstname,
              };
            }
          })*/,
        };
      });
      this.tableData = formattedTable;
    }
    // Set the initial number of items
    this.totalRows = this.items.length;

    //Chargement des cartes
    /*var departForm = document.getElementById("depart");
    var rencontreDepartForm = document.getElementById("rencontre");

    var placeDepart = new google.maps.places.Autocomplete(departForm);
    var placeRencontreDepart = new google.maps.places.Autocomplete(
      rencontreDepartForm
    );

    google.maps.event.addListener(placeDepart, "place_changed", function() {
      var place = placeDepart.getPlace();
      if (place && place.geometry) {
        this.trajet.lieuDepart.nom = place.formatted_address;
        this.trajet.lieuDepart.latitude = place.geometry.location.lat();
        this.trajet.lieuDepart.longitude = place.geometry.location.lng();
      }
    });

    /*google.maps.event.addListener(
      placeRencontreDepart,
      "place_changed",
      function() {
        var place = placeRencontreDepart.getPlace();
        if (place && place.geometry) {
          this.trajet.pointDeRencontre.nom = place.formatted_address;
          this.trajet.pointDeRencontre.latitude = place.geometry.location.lat();
          this.trajet.pointDeRencontre.longitude = place.geometry.location.lng();
        }
      }
    );*/
  },
  methods: {
    setDepart: function(place) {
      if (place) {
        /*console.log("place:", place);
        console.log('place.formatted_address:', place.formatted_address)*/
        this.trajet.lieuDepart.nom = place.formatted_address;
        this.trajet.lieuDepart.latitude = place.geometry.location.lat();
        this.trajet.lieuDepart.longitude = place.geometry.location.lng();
      }
    },
    setRencontreDepart: function(place) {
      if (place) {
        /*console.log("place:", place);
        console.log('place.formatted_address:', place.formatted_address)*/
        this.trajet.pointDeRencontre.nom = place.formatted_address;
        this.trajet.pointDeRencontre.latitude = place.geometry.location.lat();
        this.trajet.pointDeRencontre.longitude = place.geometry.location.lng();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"></h4>

            <div class="card">
              <div class="card-body">
                <form-wizard color="#5664d2">
                  <tab-content title="">
                    <div class="tab-pane" id="payment-info">
                      <!--<h5 class="card-title">Payment information</h5>
                      <p class="card-title-desc">
                        It will be as simple as occidental in fact
                      </p>-->
                      <div>
                        <div class="form-group">
                          <label class="control-label"
                            >Choisir l'utilisateur :</label
                          >
                          <multiselect
                            v-model="value"
                            :options="users"
                            label="name"
                            track-by="name"
                          ></multiselect>
                        </div>
                        <div class="col-md-12">
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="tableData"
                              :fields="fields"
                              responsive="sm"
                              :per-page="perPage"
                              :current-page="currentPage"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filter"
                              :filter-included-fields="filterOn"
                              @filtered="onFiltered"
                              @row-clicked="goToDetail"
                            ></b-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>

                  <tab-content title="Détail Trajet">
                    <div class="tab-pane" id="billing-info">
                      <form>
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-body">
                              <div class="d-flex justify-content-center">
                                Trajet effectué le
                              </div>
                              <hr style="width:40%;" />
                              <div class="row py-5">
                                <div class="col-md-6">
                                  <div class="">
                                    <div>
                                      16:43<i
                                        class="fas fa-map-marker-alt text-primary ml-2 mr-1"
                                      ></i
                                      >Cotonou
                                    </div>
                                  </div>
                                  <div class="mt-5">
                                    <div>
                                      18:51<i
                                        class="fas fa-map-marker-alt text-danger ml-2 mr-1"
                                      ></i
                                      >Bohicon
                                    </div>
                                  </div>
                                  <div class="mt-5">
                                    siège
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div>
                                    <div>
                                      <i class="fas fa-calendar-day mr-3"></i
                                      >Mode de réservation :
                                      <span class="ml-5">Sur confirmation</span>
                                    </div>
                                  </div>
                                  <div class="mt-5">
                                    <div>
                                      <i
                                        class="fas fa-search-location mr-2"
                                      ></i>
                                      Point de rencontre :
                                      <span class="ml-5">Etoile Rouge</span>
                                    </div>
                                  </div>
                                  <div class="mt-5">
                                    <div>
                                      <i class="fas fa-stopwatch mr-3 "></i
                                      >flexibilité :
                                      <span class="ml-5">0 min</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="d-flex justify-content-center">
                            <img
                              class="avatar"
                              alt
                              src="@/assets/images/users/hand.png"
                              width="250"
                              height="200"
                            />
                          </div>
                          <div class="d-flex justify-content-center">
                            <div class="row mt-5">
                              <button
                                style="width:80px;"
                                class="btn btn-outline-success btn-sm mr-4"
                              >
                                non
                              </button>
                              <!-- <button style="width:80px;" class="btn btn-success btn-lg" v-on:click="show = !show" @submit.prevent="">oui</button> -->
                            </div>
                          </div>
                          <div class="d-flex justify-content-center">
                            <div class="row mt-5 mb-5">
                              <button
                                style="width:70px;"
                                class="btn btn-success btn-xs mr-2"
                              >
                                100 XOF
                              </button>
                              <button
                                style="width:70px;"
                                class="btn btn-success btn-xs mr-2"
                              >
                                500 XOF
                              </button>
                              <button
                                style="width:70px;"
                                class="btn btn-success btn-xs"
                              >
                                1000 XOF
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </tab-content>

                  <tab-content title="Paiement">
                    <div class="tab-pane" id="billing-info">
                      <div class="row">
                        <div class="col-md-6 d-flex justify-content-center">
                          <b-card
                            header-class="bg-transparent border-primary"
                            class=""
                            style="cursor:pointer;"
                            ><img
                              class="avatar"
                              alt
                              src="@/assets/images/users/waxu.png"
                              width="250"
                              height="200"
                            />
                          </b-card>
                        </div>
                        <div class="col-md-6 d-flex justify-content-center">
                          <b-card
                            header-class="bg-transparent"
                            class=""
                            style="cursor:pointer;"
                            ><img
                              class="avatar"
                              alt
                              src="@/assets/images/users/momo.png"
                              width="250"
                              height="200"
                            />
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </tab-content>

                  <tab-content title="Récapitulatif">
                    <div class="tab-pane" id="shipping-info">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="card border rounded active shipping-address"
                          >
                            <div class="card-body">
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-map-marker mr-2"></i
                                  >Départ</span
                                >
                                <h5 class="font-size-14 float-right">
                                  Cotonou
                                </h5>
                              </div>
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-clock mr-2"></i>Heure de
                                  Départ</span
                                >
                                <h5 class="font-size-14 float-right">
                                  24-03-2021 10:00:00
                                </h5>
                              </div>
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-map-marker mr-2"></i
                                  >Arrivée</span
                                >
                                <h5 class="font-size-14 float-right">
                                  Kétou
                                </h5>
                              </div>
                              <div class="mb-2">
                                <span
                                  ><i class="fa fa-clock mr-2"></i>Heure
                                  d'Arrivée</span
                                >
                                <h5 class="font-size-14 float-right">
                                  24-03-2021 20:00:00
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div
                            class="card border rounded active shipping-address"
                          >
                            <div class="card-body">
                              <h5 class="card-title">Prix</h5>
                              <div
                                style="width: 120px;"
                                class="product-cart-touchspin"
                              >
                                <div class="input-group">
                                  <NumberInputSpinner
                                    :min="1"
                                    :max="100"
                                    :integerOnly="true"
                                    :value="5"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
